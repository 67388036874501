<template>
	<el-tabs
		class="sticky-tabs"
		type="card"
		v-model="activeName"
		:class="{ tabMargin: tabMargin }"
	>
		<el-tab-pane name="note">
			<template v-slot:label>編輯資料</template>
			<Note ref="childNote" />
		</el-tab-pane>
		<el-tab-pane name="note1" label="prompt編輯頁">
			<div class="d-flex">
				<div class="p-2 mx-auto" style="max-width: 500px">
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">營區名稱</div>
						<el-input
							placeholder="輸入營區名稱"
							v-model="promptData.user_name"
						/>
					</el-card>

					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">基本提示詞</div>
						<el-input
							type="textarea"
							:autosize="{ minRows: 4 }"
							placeholder="輸入基本提示詞"
							v-model="promptData.promptDesc2"
						>
						</el-input>
					</el-card>
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">分類提示詞</div>
						<div class="mt-2 text-start">
							<el-switch
								style="display: block"
								v-model="promptData.isEnbaled"
								active-color="#13ce66"
								inactive-color="#ff4949"
								active-text="啟用"
								inactive-text="停用"
							>
							</el-switch>
						</div>
						<el-input
							class=""
							type="textarea"
							:autosize="{ minRows: 4 }"
							placeholder="輸入分類提示詞"
							v-model="promptData.promptDesc3"
						>
						</el-input>
					</el-card>

					<!-- task1 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>任務名稱 1 / 任務描述</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[0]"
								@change="updateTask(0)"
								label="僅取得分類名稱"
							/>
						</div>
						<el-input
							placeholder="輸入任務名稱1"
							v-model="promptData.task1.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							placeholder="輸入任務範圍描述"
							v-model="promptData.task1.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input
					></el-card>

					<!-- task2 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>任務名稱 2 / 任務描述</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[1]"
								@change="updateTask(1)"
								label="僅取得分類名稱"
							/>
						</div>

						<el-input
							placeholder="輸入任務名稱2"
							v-model="promptData.task2.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							placeholder="輸入任務範圍描述"
							v-model="promptData.task2.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input
					></el-card>

					<!-- task3 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>任務名稱 3 / 任務描述</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[2]"
								@change="updateTask(2)"
								label="僅取得分類名稱"
							/>
						</div>

						<el-input
							placeholder="輸入任務名稱3"
							v-model="promptData.task3.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							placeholder="輸入任務範圍描述"
							v-model="promptData.task3.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input
					></el-card>

					<!-- task4 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>任務名稱 4 / 任務描述</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[3]"
								@change="updateTask(3)"
								label="僅取得分類名稱"
							/>
						</div>

						<el-input
							placeholder="輸入任務名稱4"
							v-model="promptData.task4.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							placeholder="輸入任務範圍描述"
							v-model="promptData.task4.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input
					></el-card>

					<!-- task5 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>任務名稱 5 / 任務描述</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[4]"
								@change="updateTask(4)"
								label="僅取得分類名稱"
							/>
						</div>

						<el-input
							placeholder="輸入任務名稱5"
							v-model="promptData.task5.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							placeholder="輸入任務範圍描述"
							v-model="promptData.task5.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input
					></el-card>

					<div class="text-end">
						<el-button type="primary" plain round @click="getData()"
							>取消(復原)</el-button
						>
						<el-button type="success" plain round @click="saveData()"
							>存檔</el-button
						>
					</div>
				</div>
			</div>
		</el-tab-pane>
		<el-tab-pane name="note2" label="回覆Line bot">
			<template v-slot:label> 回覆Line bot</template>
			<ResponseLineBot ref="childResponseLineBot" />
		</el-tab-pane>
		<!-- <el-tab-pane name="note3" label="QA對話查詢">QA對話查詢</el-tab-pane> -->
	</el-tabs>
</template>

<script>
import Note from "../ChargeMeContactBook/Note/Note.vue";
import ResponseLineBot from "../../components/ChargeMeEnterpriseGPT/ResponseLineBot.vue";
export default {
	name: "Home",
	data() {
		return {
			checkedTasks: [false, false, false, false, false],
			activeName: "note",
			textarea2: "",
			promptData: {
				isEnbaled: false,
				task1: {
					desc: "",
					id: "1",
					name: "",
					tipWord: "",
					task: "",
				},
				task2: {
					desc: "",
					id: "2",
					name: "",
					tipWord: "",
					task: "",
				},
				task3: {
					desc: "",
					id: "3",
					name: "",
					tipWord: "",
					task: "",
				},
				task4: {
					desc: "",
					id: "4",
					name: "",
					tipWord: "",
					task: "",
				},
				task5: {
					desc: "",
					id: "5",
					name: "",
					tipWord: "",
					task: "",
				},
				promptDesc2: "",
				promptDesc3: "",
				promptId: "",
				user_id: "",
				user_name: "",
			},
		};
	},
	methods: {
		updateTask(index) {
			const taskName = `task${index + 1}`;
			this.promptData[taskName].task = this.checkedTasks[index]
				? "ReturnNow"
				: "";
		},
		updateTask_2(index) {
			const taskName = `task${index + 1}`;
			console.log(this.promptData[taskName].task);
			this.checkedTasks[index] =
				this.promptData[taskName].task == "ReturnNow" ? true : false;
		},
		callChildMethod() {
			this.$refs.childNote.initNoteData();
		},
		callChildMethod_childResponseLineBot() {
			this.$refs.childResponseLineBot.initNoteData();
		},
		async saveData() {
			let vm = this;
			let promptDataCopy = JSON.parse(JSON.stringify(vm.promptData));
			Object.keys(promptDataCopy).forEach((key) => {
				if (key.startsWith("task")) {
					promptDataCopy[key].name = promptDataCopy.user_name;
					promptDataCopy[key].id = key.replace("task", "");
				}
			});
			await vm
				.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/GPTPrompt/Save`,
					promptDataCopy
				)
				.then((res) => {
					console.log(res);
					vm.notify("success", `設定成功！`);
					vm.getData();
				});
		},
		async getData() {
			let vm = this;
			await vm
				.fetchAPI("get", `${process.env.VUE_APP_URL_API}/GPTPrompt/Get`)
				.then((res) => {
					console.log(res);
					vm.promptData = res.data.response;
				});
			this.checkedTasks.forEach((isChecked, index) => {
				this.updateTask_2(index);
			});
		},
	},
	components: {
		ResponseLineBot,
		Note,
	},
	watch: {
		async activeName(newVal) {
			if (newVal == "note2") {
				this.$router.push({ name: "ResponseLineBotGPT" });
				await this.$refs.childResponseLineBot.childChatInit();
			} else if (newVal == "note1") {
				await this.getData();
			} else {
				this.callChildMethod();
				this.$router.push({ name: "EnterpriseGPTHome" });
			}
		},
	},
	computed: {
		tabMargin() {
			return this.activeName === "note" && !this.$store.getters.mobileSize;
		},
	},
	async mounted() {
		const path = this.$route.path;
		if (path.includes("ResponseLineBot") || this.$store.state.isOpenCampLineResponse) {
			this.activeName = "note2";
		}
	},
};
</script>

<style lang="scss" scoped>
.tabMargin {
	margin-left: 250px;
}
.labelcustom {
	font-size: 0.85rem;
	border-bottom: 2px solid rgba(0, 4, 255, 0.15);
	padding-bottom: 4px;
	color: black;
	margin-bottom: 8px;
	margin-top: 8px;
	font-weight: 600;
	text-align: start;
}
::v-deep .el-tabs__header {
	margin: 0 !important;
}
.box-card {
	margin-bottom: 0.5rem;
}
</style>
