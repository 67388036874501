<template>
	<el-row>
		<el-col :span="$store.getters.mobileSize ? 24 : 8">
			<div class="border-end">
				<ul class="list-group list-group-flush">
					<li
						v-for="i in chatLists"
						:key="i"
						class="list-group-item"
						@click="toChat(i)"
					>
						<div class="d-flex align-items-center">
							<el-avatar
								size="medium"
								src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
							></el-avatar>
							<div class="chatInfo ms-2 d-flex flex-column align-items-start">
								<div class="mb-1 userName">
									{{ i.name == "" ? "匿名者" : i.name }}
								</div>
								<div class="lastMessage">
									{{ i.chats[i.chats.length - 1].requestMessage }}
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</el-col>
		<el-col :span="16">
			<div
				v-if="!showChatUsers"
				class="d-flex flex-column position-relative"
				:class="{ mobileMode: $store.getters.mobileSize }"
			>
				<div class="userInfo d-flex align-items-center">
					<el-button
						class="me-2"
						v-if="$store.getters.mobileSize"
						link
						@click="showChatUsers = true"
						>返回</el-button
					>
					<div>{{ selectUser == "" ? "匿名者" : selectUser }}</div>
					<!-- <div class="markBtns ms-auto">
						<el-button type="info" plain>待處理</el-button>
						<el-button type="info" plain>處理完畢</el-button>
					</div> -->
				</div>
				<div class="d-flex flex-column p-3 chatArea">
					<div
						v-for="(chat, index) in chatHistory"
						:key="index"
						class="d-flex align-items-center justify-content-between border-bottom"
					>
						<div>
							<el-checkbox
								v-model="chat.isFinished"
								@change="updateCheckBox(chat)"
							/>
						</div>
						<div class="d-flex flex-column p-2 mb-1 w-100">
							<div class="text-start mb-2">
								<el-tag size="small" type="warning">{{
									customerServiceTriggerType[chat.customerServiceTriggerType]
								}}</el-tag>
							</div>
							<div class="chat-createdDateTime">{{ chat.createdDateTime }}</div>
							<div class="me-auto chatPopup p-2">
								{{ chat.requestMessage }}
							</div>

							<div
								v-if="chat.isCustomerServiceReply || chat.isFinished"
								class="ms-auto chatPopup-send p-2 mt-2"
							>
								<div>{{ chat.customerServiceReplyMessage }}</div>
							</div>
							<div class="d-flex mt-2" style="font-size: x-small">
								<div class="me-2">
									<i
										v-if="chat.isCustomerServiceReply"
										class="fa-sharp fa-light fa-square-check"
									></i>
									<i v-else class="fa-sharp fa-light fa-square"></i>
									<span> 已通知會員</span>
								</div>

								<span>
									<i
										v-if="chat.isRecordNote"
										class="fa-sharp fa-light fa-square-check"
									></i>
									<i v-else class="fa-sharp fa-light fa-square"></i>
									已存記事本</span
								>
							</div>
						</div>
						<el-button
							v-if="!chat.isFinished"
							type="success"
							size="mini"
							plain
							@click="handleShow(chat)"
							circle
							><i class="fa-regular fa-message-dots"></i
						></el-button>
						<el-button
							v-else-if="chat.isFinished"
							type="primary"
							size="mini"
							plain
							@click="saveMessage(chat)"
							circle
							><i class="fa-sharp fa-light fa-floppy-disk"></i
						></el-button>
					</div>
				</div>
				<div class="messageArea" v-if="editObj != null">
					<div class="text-start mb-2">
						回覆於 "{{ editObj.requestMessage }}" 的內容
					</div>
					<div class="d-flex align-items-center">
						<el-input
							:disabled="editObj.requestMessage == null"
							type="textarea"
							size="small"
							v-model="editMessage"
							placeholder="輸入內容..."
							:autosize="{ minRows: 3, maxRows: 5 }"
						></el-input>
						<el-button
							:disabled="editObj.requestMessage == null"
							class="ms-2"
							@click="sendEditMessage(editObj)"
							type="info"
							plain
							circle
							><i class="fa-sharp fa-light fa-paper-plane-top"></i
						></el-button>
					</div>
					<div>
						<el-checkbox v-model="editObj.isInformUser"
							>Line通知會員</el-checkbox
						>
						<el-checkbox v-model="editObj.isNeedRecordNote"
							>寫入記事本</el-checkbox
						>
					</div>
				</div>
			</div>
			<div v-else-if="!$store.getters.mobileSize && showChatUsers" class="pt-5">
				尚未選擇使用者
			</div>
		</el-col>
	</el-row>
</template>

<script>
export default {
	name: "Chat",
	data() {
		return {
			chatUserList: [
				{
					userId: 0,
					userName: "測試1",
					lastMessage: "您好",
					lastMessageDateTime: "2024-12-01",
					avatar: "https://example.com/avatar1.png",
				},
				{
					userId: 1,
					userName: "測試2",
					lastMessage: "怎麼了？",
					lastMessageDateTime: "2024-11-30",
					avatar: "https://example.com/avatar2.png",
				},
				{
					userId: 2,
					userName: "測試3",
					lastMessage: "我有點忙，等下再聊。",
					lastMessageDateTime: "2024-11-29",
					avatar: "https://example.com/avatar3.png",
				},
				{
					userId: 3,
					userName: "測試4",
					lastMessage: "好的，謝謝！",
					lastMessageDateTime: "2024-11-28",
					avatar: "https://example.com/avatar4.png",
				},
				{
					userId: 4,
					userName: "測試5",
					lastMessage: "我已經發送給你了。",
					lastMessageDateTime: "2024-11-27",
					avatar: "https://example.com/avatar5.png",
				},
				{
					userId: 5,
					userName: "測試6",
					lastMessage: "可以幫忙一下嗎？",
					lastMessageDateTime: "2024-11-26",
					avatar: "https://example.com/avatar6.png",
				},
				{
					userId: 6,
					userName: "測試7",
					lastMessage: "沒問題，馬上處理。",
					lastMessageDateTime: "2024-11-25",
					avatar: "https://example.com/avatar7.png",
				},
				{
					userId: 7,
					userName: "測試8",
					lastMessage: "確認一下細節。",
					lastMessageDateTime: "2024-11-24",
					avatar: "https://example.com/avatar8.png",
				},
				{
					userId: 8,
					userName: "測試9",
					lastMessage: "稍等，我查一下。",
					lastMessageDateTime: "2024-11-23",
					avatar: "https://example.com/avatar9.png",
				},
				{
					userId: 9,
					userName: "測試10",
					lastMessage: "我們明天再討論。",
					lastMessageDateTime: "2024-11-22",
					avatar: "https://example.com/avatar10.png",
				},
			],
			showChatUsers: true,
			chatList: [],
			selectUser: "",
			chatLists: [],
			chatHistory: [],
			activeMenu: "1",
			currentComponent: "ChatComponent", // 默認顯示的組件
			editShow: false,
			editObj: {},
			editMessage: "",
			customerServiceTriggerType: {
				CustomerService: "真人客服",
				NotHelpful: "沒幫助",
				NotFoundData: "找不到資料",
			},
		};
	},
	methods: {
		sendEditMessage(item) {
			const vm = this;
			const data = {
				CamperId: vm.currentUser().id,
				MessageId: item.id,
				CustomerServiceReplyMessage: vm.editMessage,
				ShowUserMessage: `問題:\n${item.requestMessage}\n\n回答:\n${vm.editMessage}`,
				IsInformUser: item.isInformUser,
				IsRecordNote: item.isNeedRecordNote,
			};
			if (
				data.CustomerServiceReplyMessage == "" ||
				data.CustomerServiceReplyMessage == null
			) {
				vm.notify("warning", `回覆不可為空白！`);
				return;
			}
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API_BE}/LineBotMessage/SendMessageBoard`,
				data
			)
				.then((res) => {
					vm.editMessage = "";
					item.customerServiceReplyMessage =
						res.data.customerServiceReplyMessage;
					item.isCustomerServiceReply = res.data.isCustomerServiceReply;
					item.isRecordNote = res.data.isRecordNote;
					item.isFinished = res.data.isFinished;
					vm.notify("success", `回覆成功！`);
					vm.editShow = false;
				})
				.catch((err) => {
					console.log(err.response.data);
					vm.notify("error", `回覆失敗！`);
				});
		},
		saveMessage(item) {
			const vm = this;
			const data = {
				MessageId: item.id,
				CustomerServiceReplyMessage: item.customerServiceReplyMessage,
				ShowUserMessage: ``,
				IsInformUser: false,
				IsRecordNote: true,
				CamperId: vm.currentUser().id,
			};

			console.log("1", item, "2", data);
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API_BE}/LineBotMessage/SendMessageBoard`,
				data
			)
				.then((res) => {
					vm.editMessage = "";
					item.customerServiceReplyMessage =
						res.data.customerServiceReplyMessage;
					item.isCustomerServiceReply = res.data.isCustomerServiceReply;
					item.isRecordNote = res.data.isRecordNote;
					item.isFinished = res.data.isFinished;
					vm.notify("success", `儲存成功！`);
					vm.editShow = false;
				})
				.catch((err) => {
					console.log(err.response.data);
					vm.notify("error", `儲存失敗！`);
				});
		},
		handleMenuSelect(index) {
			this.activeMenu = index;
			switch (index) {
				case "1":
					this.currentComponent = "ChatComponent";
					this.$router.push({ name: "ResponseLineBotGPTChat" }); // 假設路由為 /chat
					break;
				case "2":
					this.currentComponent = "ContactComponent";
					this.$router.push({ name: "ResponseLineBotGPTContact" }); // 假設路由為 /contact
					break;
				case "3":
					this.currentComponent = "FilterMessageComponent";
					this.$router.push({ name: "ResponseLineBotGPTFilterMessage" }); // 假設路由為 /filter
					break;
				default:
					this.currentComponent = "ChatComponent"; // 默認返回聊天
			}
		},
		async loadData() {
			let vm = this;
			vm.fetchAPI(
				"GET",
				`${
					process.env.VUE_APP_URL_API_BE
				}/LineBotMessage/LineBotMessageByCamperId?userId=${vm.currentUser().id}`
			)
				.then((res) => {
					console.log(res);

					let messageList = res.data.map((item) => {
						return {
							...item,
							isInformUser: true,
							isNeedRecordNote: false,
						};
					});

					vm.chatLists = vm.groupedData(messageList);
					console.log(vm.chatLists);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		groupedData(data) {
			const grouped = data.reduce((acc, item) => {
				const key = `${item.memberName}-${item.memberId}`; // 組合 key
				if (!acc[key]) {
					acc[key] = {
						name: item.memberName, // 将 memberName 添加到对象
						memberId: item.memberId, // 将 memberId 添加到对象
						chats: [], // 初始化 chats 数组
					};
				}
				acc[key].chats.push(item); // 將當前項目推入對應的 chats
				return acc;
			}, {});

			// 將累加器的值轉換為數組
			return Object.values(grouped);
		},
		handleShow(item) {
			this.editShow = true;
			this.editObj = item;
			this.editMessage = item.customerServiceReplyMessage;
		},
		updateCheckBox(temp) {
			const vm = this;
			const data = {
				MessageId: temp.id,
				IsFinished: temp.isFinished,
			};

			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API_BE}/LineBotMessage/UpdateFinishStatus`,
				data
			)
				.then((res) => {
					console.log(res.data);
					vm.notify("success", `處理狀態更新成功！`);
				})
				.catch((err) => {
					console.log(err.response.data);
					vm.notify("error", `處理狀態更新失敗！`);
				});
		},
		toChat(item) {
			let vm = this;
			vm.selectUser = item.name;
			vm.chatHistory = item.chats;
			vm.editObj = {};
			vm.showChatUsers = false;
		},
	},
	async mounted() {
		let vm = this;
		await vm.loadData();
		const path = vm.$route.path;
		if (path.includes("Chat")) {
			this.activeMenu = "1";
		} else if (path.includes("Contact")) {
			this.activeMenu = "2";
		} else if (path.includes("FilterMessage")) {
			this.activeMenu = "3";
		} else {
			this.activeMenu = "1"; // 默認為聊天
			// this.$router.push({ name: "ResponseLineBotGPTChat" }); // 假設路由為 /chat
		}
	},
};
</script>

<style scoped>
.userInfo {
	padding: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.chatInfo {
	padding: 1rem;
	.userName {
		font-size: 14px;
	}
	.lastMessage {
		font-size: 12px;
	}
}

.chatPopup {
	background-color: #f2f3f5;
	padding: 5px;
	border-radius: 8px;
	white-space: pre-wrap;
}
.chatPopup-send {
	background-color: #d1dcf5;
	padding: 5px;
	border-radius: 8px;
	white-space: pre-wrap !important;
	text-align: start;
}
.chat-createdDateTime {
	font-size: xx-small;
	text-align: start;
	margin-bottom: 6px;
}
.messageArea {
	/* position: absolute; */
	bottom: 0;
	width: 100%;
	/* background-color: aqua; */

	border-top: 1px solid rgba(0, 0, 0, 0.15);
	padding: 15px;
}
.chatArea {
	height: 70vh;
	overflow-y: auto;
}
.mobileMode {
	position: fixed !important;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	background-color: white;
	padding-top: 100px;
}
</style>
